import React from "react"

import CiPilliersStyle from "./ci-pilliers.module.scss";

const CiPilliersFr = (props) => {

        return         <section className={ CiPilliersStyle.expertise }>
        <div className={ CiPilliersStyle.inner }>
            <div className={ CiPilliersStyle.left }>
                <div className={ CiPilliersStyle.content }>
                    <h3>In a detailed drill-down, the analysis evaluates</h3>
                    <h2>6 pillars</h2>
                </div>
            </div>
            <div className={ CiPilliersStyle.right }>
                <div className={ CiPilliersStyle.line }>
                    <div className={ CiPilliersStyle.bloc }>
                        <div className={ CiPilliersStyle.content }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Expertise.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Quality - Expertise, empathy & authenticity</div>
                            <div className={ CiPilliersStyle.texte }>We measure the quality of the experience offered in these three dimensions.</div>
                        </div>
                    </div>
                    <div className={[CiPilliersStyle.bloc, CiPilliersStyle.grey].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Scalabilite%CC%81.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Scalability</div>
                            <div className={ CiPilliersStyle.texte }>Do you have the means to offer this experience to all your customers, even during peak periods?</div>
                        </div>
                    </div>
                    <div className={ CiPilliersStyle.bloc }>
                        <div className={ CiPilliersStyle.content }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Projet%20dentreprise.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Business project</div>
                            <div className={ CiPilliersStyle.texte }>Is your conversational strategy at the heart of your DNA?</div>
                        </div>
                    </div>
                </div>
                <div id={ CiPilliersStyle.secondLine } className={ CiPilliersStyle.line }>
                <div className={[CiPilliersStyle.bloc, CiPilliersStyle.grey].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Projet%20dentreprise.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Engagement and personalization</div>
                            <div className={ CiPilliersStyle.texte }>We're interested in how you engage in conversation with your customers.</div>
                        </div>
                    </div>
                    <div className={[CiPilliersStyle.bloc, CiPilliersStyle.white].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Messaging%20riche.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Rich and easy-to-use messaging</div>
                            <div className={ CiPilliersStyle.texte }>We look at how messaging fits into your digital and mobile experience.</div>
                        </div>
                    </div>
                    <div className={[CiPilliersStyle.bloc, CiPilliersStyle.grey].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Omnicanal.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Fluidity & omnicanality</div>
                            <div className={ CiPilliersStyle.texte }>Do you offer your customers a seamless experience via messaging, beyond your site?</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
}

export default CiPilliersFr

import React from "react";

import { Link } from 'gatsby';

import CiHeaderStyle from "./ci-header.module.scss";

const CiHeaderUsa = (props) => {

    return <section className={ CiHeaderStyle.header }>
    <div className={ CiHeaderStyle.inner }>
      <div className={ CiHeaderStyle.left }>
        <h1>
          <div id={ CiHeaderStyle.word }>Assess</div>your conversational maturity<span>.</span>
        </h1>
        <div className={ CiHeaderStyle.legend }>
          Measure across 6 pillars of the CMI methodology. Benchmark yourself against industry leaders and the competition.
        </div>

        <Link to={"questionnaire"}>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Arrow%202.png" alt="" />
          Start the assessment
        </Link>

      </div>
      <div className={ CiHeaderStyle.right }>
        <div id={ CiHeaderStyle.star } className={ CiHeaderStyle.star }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Frame%2011987.png"></img>
        </div>
        <div id={ CiHeaderStyle.score } className={ CiHeaderStyle.score }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score-4.png"></img>
        </div>
        <div id={ CiHeaderStyle.ticks } className={ CiHeaderStyle.ticks }>
          <div className={ CiHeaderStyle.tick }>
            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201.png" alt="" />
            <span>Take the test</span>
          </div>
          <div className={ CiHeaderStyle.tick }>
            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201.png" alt="" />
            <span>Get your score</span>
          </div>
          <div className={ CiHeaderStyle.tick }>
            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201.png" alt="" />
            <span>Identify your areas of improvement</span>
          </div>
        </div>
      </div>
      <div className={ CiHeaderStyle.assurance }>
        <div className={ CiHeaderStyle.bloc }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Group.png" alt="" />
          <h3>A methodology based on 10 years of expertise</h3>
          <div className={ CiHeaderStyle.content }>Working with leading brands such as Samsung, ManoMano, and OtterBox —  iAdvize developed a unique methodology to analyze a company’s digital strategy, the Conversational Maturity Index.</div>
        </div>
        <div className={ CiHeaderStyle.bloc }>
        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%2029.png" alt="" />
          <h3>An interactive and instantaneous experience</h3>
          <div className={ CiHeaderStyle.content }>Complete the online version of this assessment consisting of 19 base-line questions. Gain an objective overview of your conversational maturity in less than 10 minutes.</div>
        </div>
        <div className={ CiHeaderStyle.bloc }>
        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Group-1.png" alt="" />
          <h3>An accurate understanding of your situation</h3>
          <div className={ CiHeaderStyle.content }>With your score comes an analysis of your strengths and areas of improvement along the 6 pillars. Additionally, receive exclusive insights on where you stand relative to the rest of your industry.</div>
        </div>
      </div>
    </div>
  </section>
  
}

export default CiHeaderUsa

import React, { Component } from 'react'


import CiMenu from "../components/conversational-index/usa/ci-menu/ci-menu";
import CiHeaderUsa from "../components/conversational-index/usa/ci-header/ci-header";
import CiWhyUsa from "../components/conversational-index/usa/ci-why/ci-why";
import CiMethodoUsa from "../components/conversational-index/usa/ci-methodo/ci-methodo";
import CiPilliersUsa from "../components/conversational-index/usa/ci-pilliers/ci-pilliers";
import CiFooterUsa from "../components/conversational-index/usa/ci-footer/ci-footer";
import CiJoinUsa from "../components/conversational-index/usa/ci-join/ci-join";
import CiForresterUsa from "../components/conversational-index/usa/ci-forrester/ci-forrester";
import CiScore from "../components/conversational-index/usa/ci-score/ci-score";

import SEO from "../components/seo";


class HomePage extends Component {

    render(){

        return(
            <>
                <SEO title="Conversational Maturity Index: Evaluate your Company's Conversational Maturity." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index%20(1).png" lang="en" description="By completing the online version of this audit, consisting of 19 simple questions, you will get an objective overview of your conversational maturity in less than 10 minutes."/>
                <CiMenu/>
                <CiHeaderUsa/>
                <CiWhyUsa/>
                <CiMethodoUsa/>
                <CiScore/>
                <CiPilliersUsa/>
                <CiForresterUsa/>
                <CiJoinUsa/>
                <CiFooterUsa/>
            </>
        )
    }

}

export default HomePage
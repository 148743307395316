import React from "react"

import CiMethodoStyle from "./ci-methodo.module.scss";

const CiMethodoFr = (props) => {

        return <section className={ CiMethodoStyle.methodo }>
            <div className={ CiMethodoStyle.inner }>
            <h3>A revolutionary and<span>unique methodology</span></h3>
            <div className={ CiMethodoStyle.content }>
                The Conversational Index is the result of more than <strong>10 years supporting the most innovative brands through their CX elevation journeys.</strong> This assessment allows our clients to assess the maturity of their conversational strategy at any time.
                <br/><br/>
                    <span>An online, short format</span>
                <br/><br/>
                Here is your chance to perform the short format of this assessment, consisting of 19 multiple choice questions. Be completely honest in your answers to obtain the most accurate result possible, and to clearly situate yourself in relation to your market.
            </div>
            </div>
        </section>
  
}

export default CiMethodoFr




import React from "react"
import { Button } from 'react-bootstrap';

import CiForresterStyle from "./ci-forrester.module.scss";

const CiForresterFr = (props) => {

        return         <section className={ CiForresterStyle.forrester }>
        <div className={ CiForresterStyle.inner }>
            <div className={ CiForresterStyle.left }>
                <div className={ CiForresterStyle.content }>
                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Forrester.gif" alt=""/>
                    <div className={ CiForresterStyle.text }>
                        Digital interactions with your customers will  <span>increase by 40% in 2021.</span>*
                    </div>
                    <div className={ CiForresterStyle.legend }>
                        *Forrester, Predictions 2021: Customer Service
                    </div>
                </div>
            </div>
            <div className={ CiForresterStyle.right }>
                <div className={ CiForresterStyle.content}>
                    Are you ready to communicate through the same authentic and seamless conversational experience that customers have everyday, messaging with family and friends?
                </div>
            </div>
        </div>
    </section>
  
}

export default CiForresterFr




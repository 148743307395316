import React from "react"

import CiWhyStyle from "./ci-why.module.scss";

const CiWhyFr = (props) => {

    return <section id={ CiWhyStyle.whySection } className={ CiWhyStyle.why }>
            <div className={ CiWhyStyle.inner }>

                <div className={ CiWhyStyle.left}>
                <div className={ CiWhyStyle.content}>
                <img id={ CiWhyStyle.logo} src="https://info.iadvize.com/hubfs/CI%20-%202021/Logo%20CI.png" alt=""/>
                    <div className={ CiWhyStyle.preview }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Img%20-%20Quizz.png"></img>
                    </div>
                </div>
                </div>

                <div className={ CiWhyStyle.right}>
                    <div className={ CiWhyStyle.top}> 
                        <h2>Identify</h2>
                        <h3>priorities to improve your digital experience</h3>
                    </div>
                    <div className={ CiWhyStyle.bottom}>
                        <h3>
                            An accurate and objective evaluation
                        </h3>
                        <div className={ CiWhyStyle.content }>
                            The Conversational Maturity Index is based on a methodology designed and validated by <a href="https://wwww.iadvize.com/en/">iAdvize</a> combining multiple choice questions and qualitative analysis.<br/><br/>
                            Providing an accurate and objective evaluation, it enables brands to assess their conversational maturity and identify the key issues to help them improve their digital experience.
                        </div>
                    </div>
                </div>

            </div>
        </section>
  
}

export default CiWhyFr
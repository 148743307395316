import React from "react";

import { Tab, Row, Nav, Col, Sonnet, Container } from 'react-bootstrap';

import CiScoreStyle from "./ci-score.module.scss";

import "./custom.scss";

const CiScoreFr = (props) => {

    return <section className={ CiScoreStyle.score }>
            <div className={ CiScoreStyle.inner }>

                <div className={ CiScoreStyle.title }>
                    <h2>
                        <span>Get</span>
                        your score now
                    </h2>

                    <div className={ CiScoreStyle.legend }>
                        <strong>In 10 minutes,</strong>  test your brand against a series of assessment points, and receive a score representing your level of maturity, plus a report with your detailed results.
                    </div>
                </div>

                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>

                        <Col>
                            <Tab.Content className="custom-nav-bg">
                                <Tab.Pane eventKey="first">
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score-3.png"></img>   
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score-1-1.png"></img>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score-2-1.png"></img>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>

                        <Col>
                            <Nav className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" className="button">
                                        <div className="icon" id="btn1"></div>
                                        <h2>Industry overview</h2>
                                        <div className="text">Your situation relative to the leaders in your industry</div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" className="button">
                                        <div className="icon" id="btn2"></div>
                                        <h2>Strengths</h2>
                                        <div className="text">A summary of the strengths of your digital experience</div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item> 
                                    <Nav.Link eventKey="third" className="button">
                                        <div className="icon" id="btn3"></div>
                                        <h2>Areas of improvement</h2>
                                        <div className="text">Solutions to achieve conversational maturity across the 6 pillars</div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>

                    </Row>
                </Tab.Container>

            </div>
        </section>

}

export default CiScoreFr

